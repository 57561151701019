<template>
	<div class="container-fluid">
		<div class="row">
			<div class="col-lg-12">
				<div class="d-flex flex-wrap flex-wrap align-items-center justify-content-center">
                    <div class="text-center">
                        <h4 class="mb-3">{{title}}</h4>
                        <p class="mb-0">{{desc1}}<br> {{desc2}}&nbsp;&nbsp;&nbsp;&nbsp;<span v-b-modal.modal-3 variant="link" class="mb-0"><u>수집이 잘 되지 않는 경우 조치방법</u></span></p>
							<b-modal id="modal-3" size="lg" title="수집이 잘 되지 않는 경우 조치방법" ok-only>
								<p>1. 확장 설치 후 쿠플러스 사이트를 닫았다가 열어서 다시 로그인 후에 키워드 분석 시도하기</p>
								<p>2. 크롬 브라우저 사용하기</p>
								<p>3. 확장앱 중 1688/타오바오/알리바바 이미지서치 앱 삭제</p>
								<p>4. 크롬 브라우저의 계정을 별도의 계정으로 추가해서 쿠플러스만 확장 설치 후 실행하기</p>
                            </b-modal>
                    </div>
                </div>
			</div>
			<!-- <div class="col-lg-12">
				<div class="d-flex justify-content-center">
					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="customRadio2" v-model="analysisType" name="customRadio-2" class="custom-control-input" value="total" @change="changeAnalysisType">
						<label class="custom-control-label" for="customRadio2"> 매출+조회수 </label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="customRadio1" v-model="analysisType" name="customRadio-2" class="custom-control-input" value="keywordSales" @change="changeAnalysisType">
						<label class="custom-control-label" for="customRadio1"> 키워드매출 </label>
					</div>
					<div class="custom-control custom-radio custom-control-inline">
						<input type="radio" id="customRadio3" v-model="analysisType" name="customRadio-2" class="custom-control-input" value="keywordImpressions" @change="changeAnalysisType">
						<label class="custom-control-label" for="customRadio3"> 키워드조회수 </label>
					</div>
				</div>
			</div> -->
			<div class="col-lg-12">
				<div class="d-flex justify-content-center" style="height:150%">
					<input ref="cursor" type="text" class="form-control w-25 h-100 text-center rounded-pill" placeholder="검색키워드" v-model="targetKeyword" @keyup.enter="searchenter($event)">
					<div class="d-flex justify-content-center align-items-center ml-4">
						<button type="button" class="btn btn-primary" @click="search">키워드 분석</button>
						<button type="button" class="btn btn-success ml-2" v-b-modal.modal-4>대량수집설정 </button>
						<div v-if="showKeywordGroup">
							<b-dropdown id="dropdown-1" text="키워드그룹설정" class="m-md-2">
								<b-dropdown-item v-for="keyword in keywordGroup" :key="keyword"
												:active="keyword === activeKeyword"
												>
									<div class="d-flex justify-content-between align-items-center">
										<div @click="setActiveKeyword(keyword)">
											{{keyword}}
										</div>
										<b-badge variant="secondary" v-if="keyword !== 'Default'" @click="deleteKeywordGroup(keyword)">Del</b-badge>
									</div>
								</b-dropdown-item>
								<b-dropdown-divider v-if="keywordGroup.length > 0"></b-dropdown-divider>
								<b-dropdown-item :disabled="keywordGroup.length >= 10 && !this.$store.state.user.auth.showdeveloper" @click="createKeywordGroup">새 키워드그룹</b-dropdown-item>
								<!-- <b-dropdown-item @click="collectAllKeyword">그룹전체수집</b-dropdown-item> -->
							</b-dropdown>
						</div>
						<!-- <div class="d-flex flex-column"> -->
							<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
								<input type="checkbox" class="custom-control-input" id="customSwitch1" v-model="related">
								<label class="custom-control-label" for="customSwitch1">연관검색어</label>
							</div> -->
							<!-- <div class="custom-control custom-switch custom-control-inline ml-3">
								<input type="checkbox" class="custom-control-input" id="customSwitch2" v-model="keywordAnalysisEnable">
								<label class="custom-control-label" for="customSwitch2">키워드분석</label>
							</div> -->
						<!-- </div> -->

						<b-modal id="modal-4" size="md" title="대량수집설정" ok-title="수집시작" cancel-title="닫기" @ok="collectKeyword">
							<div class="row">
								<div class="col-md-12">
									<div class="form-group">
										<label  for="Code">'Upload'양식을 받아서 수집할 키워드를 업로드 해주세요.</label>
										<label  for="Code">현재키워드 갯수 : {{ autoList.length }} / 100</label>
										<div class="d-flex justify-content-center">
											<button type="reset" @click="excelDownFunc" class="btn btn-primary mr-2">Upload</button>
											<div class="custom-file">
												
												<input ref="pdbupload" type="file" @change="uploadfile" class="custom-file-input" id="inputGroupFile02">
												<label class="custom-file-label" for="inputGroupFile02">{{uploadstatus}}</label>
											</div>
										</div>
										
										<div class="d-flex justify-content-center align-items-center mt-2 row">
											<input type="text" class="form-control col-8" placeholder="수집키워드 직접추가" v-model="addKeyword" @keyup.enter="addRelKeywordEnter($event)">
											<button type="reset" @click="addRelKeyword" class="btn btn-success ml-2 col-3">키워드추가</button>
										</div>

										<div class="col-md-12" v-if="showuploadspinner">
											<div>
												<img style="width:5%;" :src="require('../../../assets/images/small/loading.gif')">
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-12" v-if="showAutoList">
									<div class="table-responsive">
										<table id="datatable" class="table data-table table-striped dataTable text-center" style="font-size:90%">
											<thead>
												<tr class="ligth ligth-data">
													<th style="font-size:90%">키워드</th>
													<th style="font-size:90%">연관키워드</th>
													<th style="font-size:90%">수집여부</th>
													<th style="font-size:90%">삭제</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(list,index) in autoList" :key="index">
													<td>{{list.targetKeyword}}</td>
													<td>
														<div v-if="list.연관키워드">
															<b-button disabled variant="outline-primary">추가</b-button>
														</div>
														<div v-else>
															<b-button variant="primary" @click="importRel(list)">추가</b-button>
														</div>
													</td>
													<td><div :class="keywordClass(list.chartData)" @click="searchKeyword(list)">{{list.chartData ? 'Y' : 'N'}}</div></td>
													
													<td>
														<a class="badge bg-warning mr-2" data-toggle="tooltip" v-b-tooltip.top title="Delete" href="javascript:void(0)" @click="deletelist(index)">
															<i class="ri-delete-bin-line mr-0"></i>
														</a>
													</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div class="d-flex justify-content-between">
										<button type="button" class="btn btn-success mr-2 mb-4" @click="resetAutoList">전체삭제</button>
										<div class="text-center d-flex justify-content-end align-items-center h-100">
											Delay(ms)
											<input type="number" class="form-control ml-2 mr-2" placeholder="수집 delay(ms) 설정 " v-model="delayTime">
											<button type="button" class="btn btn-primary h-100" @click="collectAll" v-b-tooltip.left="'이미 수집된 것까지 포함하여 전체키워드를 수집합니다.'">전체수집</button>
										</div>
										
									</div>
								</div>
							</div>
						</b-modal>
						
					</div>

				</div>
				
			</div>
			
				
			<div class="col-lg-12 mt-5" v-if="showspinner">
				<div class="custom-control custom-control-inline d-flex justify-content-center">
					<div class="row">
						<div class="col-12 d-flex justify-content-center">
							<img style="max-width:50px;" :src="require('../../../assets/images/small/loading.gif')">
							<span>{{loadingtext}}... {{loadingrate}}</span>
						</div>
					</div>
				</div>
			</div>
			<div class="col-lg-12 d-flex justify-content-center flex-column mt-3" v-if="showlist">
				<div class="d-flex justify-content-start">
					<div>
						<div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
							<input type="radio" id="customRadio1" v-model="analysisType" name="customRadio-2" class="custom-control-input bg-success" value="keywordSales" @change="changeAnalysisType">
							<label class="custom-control-label" for="customRadio1"> 키워드매출 </label>
						</div>
						<div class="custom-control custom-radio custom-radio-color-checked custom-control-inline">
							<input type="radio" id="customRadio3" v-model="analysisType" name="customRadio-2" class="custom-control-input bg-success" value="keywordImpressions" @change="changeAnalysisType">
							<label class="custom-control-label" for="customRadio3"> 키워드조회수 </label>
						</div>
						<div class="custom-control custom-radio custom-radio-color-checked custom-control-inline mr-4" v-if=showNomadv3>
							<input type="radio" id="customRadio4" v-model="analysisType" name="customRadio-2" class="custom-control-input bg-success" value="keywordCVR" @change="changeAnalysisType">
							<label class="custom-control-label" for="customRadio4"> 키워드전환율 </label>
						</div>
						<div class="custom-control custom-radio custom-control-inline">
							<input type="radio" id="customRadio7" v-model="salesUnit" name="customRadio-1" class="custom-control-input ml-4" value="daily" @change="changeSalesUnit">
							<label class="custom-control-label" for="customRadio7"> Daily </label>
						</div>
						<div class="custom-control custom-radio custom-control-inline">
							<input type="radio" id="customRadio6" v-model="salesUnit" name="customRadio-1" class="custom-control-input" value="monthly" @change="changeSalesUnit">
							<label class="custom-control-label" for="customRadio6"> Monthly </label>
						</div>
						
					</div>
				</div>
				<b-card>
					<ApexChart element="layout1-chart-5" :chartOption="chart" />
				</b-card>
			</div>

			<div class="col-lg-12 d-flex justify-content-start" v-if="showKeywordList">
				<b-button variant="success" class="mr-2" v-b-toggle="'targetKeywordAnalysis'">카테고리 / 타겟키워드 / 초도구매분석</b-button>
				<b-button variant="primary" class="" v-b-modal="'marketAbility'">상품성 분석</b-button>
			</div>
			<b-collapse id="targetKeywordAnalysis" class="col-lg-12">
				<b-card>
					<div class="p-3">
						<div class="row d-flex justify-content-start align-items-center">
							<b-button variant="outline-primary" class="mb-1 mr-2" @click="showAll">
								전체 : <b-badge variant="primary" class="ml-2">{{AllSum.toLocaleString()}}원</b-badge> / 메인키워드 : {{mainKeywordAll}} 
							</b-button>
							<b-button variant="outline-secondary" class="mb-1 mr-2" @click="show1st" v-if="categoryCode1st">
								카테고리1순위 : {{categoryCode1st}}<b-badge variant="secondary" class="ml-2">{{categoryCode1stSum.toLocaleString()}}원</b-badge> / 메인키워드 : {{mainKeyword1st}} 
							</b-button>
							<b-button variant="outline-success" class="mb-1 mr-2" @click="show2nd" v-if="categoryCode2nd">
								카테고리2순위 : {{categoryCode2nd}}<b-badge variant="success" class="ml-2">{{categoryCode2ndSum.toLocaleString()}}원</b-badge> / 메인키워드 : {{mainKeyword2nd}} 
							</b-button>
							<b-button variant="outline-warning" class="mb-1 mr-2" @click="show3rd" v-if="categoryCode3rd">
								카테고리3순위 : {{categoryCode3rd}}<b-badge variant="warning" class="ml-2">{{categoryCode3rdSum.toLocaleString()}}원</b-badge> / 메인키워드 : {{mainKeyword3rd}} 
							</b-button>
						</div>
						<div class="row d-flex justify-content-start mt-4">
							<div class="text-center">
								<span>일 리뷰수량</span>
								<input type="number" class="form-control" placeholder="일 리뷰수량" v-model="targetPurchase" @change="calPurchase">
							</div>
							<div class="text-center">
								<span>x</span>
							</div>
							<div class="text-center">
								<span>판매가</span>
								<input type="number" class="form-control" placeholder="판매가" v-model="targetPrice" @change="calPurchase">
							</div>
							<div class="text-center">
								<span>=</span>
							</div>
							<div class="text-center">
								<span>일 매출</span>
								<input type="number" class="form-control" placeholder="일 매출" v-model="daySales" disabled>
							</div>
							<div class="text-center ml-2">
								<div class="d-flex justify-content-center align-items-center">
									<b-badge variant="success" class="mr-1" @click="prevKeyword">◀</b-badge><span>타겟키워드(적합도%)</span><b-badge variant="success" class="ml-1" @click="nextKeyword">▶</b-badge>
								</div>
								<input type="text" class="form-control" placeholder="타겟키워드" v-model="targetKeyword1st" disabled>
							</div>
							<div class="text-center ml-2">
								<span>타겟순위</span>
								<input type="number" class="form-control" placeholder="타겟순위" v-model="targetRank" disabled>
							</div>
							<div class="text-center ml-2">
								<span>타겟 일조회수</span>
								<input type="number" class="form-control" placeholder="타겟일조회수" v-model="targetClick" disabled>
							</div>
							<div class="text-center">
								<span>x</span>
							</div>
							<div class="text-center">
								<span>결제전환율(%)</span>
								<input type="number" class="form-control" placeholder="결제전환율(%)" v-model="targetCVR" @change="changePurchase">
							</div>
							<div class="text-center">
								<span>x</span>
							</div>
							<div class="text-center">
								<span>재고기간</span>
								<input type="number" class="form-control" placeholder="재고기간" v-model="targetStockPeriod" @change="changePurchase">
							</div>
							<div class="text-center">
								<span>x</span>
							</div>
							<div class="text-center">
								<span>보정치(%)</span>
								<input type="number" class="form-control" placeholder="보정치(%)" v-model="correction" @change="changePurchase">
							</div>
							<div class="text-center">
								<span>=</span>
							</div>
							<div class="text-center">
								<span>초도구매수량</span>
								<input type="number" class="form-control" placeholder="구매수량" v-model="purchaseQty" disabled>
							</div>
						</div>
					</div>
				</b-card>
			</b-collapse>
			<b-modal id="marketAbility" size="xl" class="col-lg-12" ok-only ok-title="닫기">
				<b-card>
					<button type="button" class="btn btn-success" @click="downloadMarketAbility">엑셀다운로드</button>
					<div class="p-3">
						<CDataTable :headers="marketAbilityHeaders" :items="productList" v-model="selectedProductItem" :show-select="true" itemKey="노출ID">
							<template v-slot:뱃지="{item}">
								<div style="width:50px;overflow:hidden;text-overflow: ellipsis;">
									<span v-if="!item.뱃지">없음</span><img v-if="item.뱃지" :src="item.뱃지" alt="image" style="max-width:100%">
								</div>
							</template>
							<template v-slot:판매링크="{item}">
								<a :href="item.판매링크"
									target="_blank">
									<b-icon icon="link" scale="2" variant="primary"></b-icon>
								</a>
							</template>
						</CDataTable>
					</div>
					
				</b-card>
			</b-modal>

			<card class="col-lg-12" v-if="showKeywordList">
				<div class="p-3">
					<CDataTable :headers="headers" :items="keywordList" v-model="tableSelectedItem" :show-select="true" itemKey="_id">
						<template v-slot:category="{item}">{{item.category || ''}}</template>
						<template v-slot:categoryCode1="{item}">{{item.categoryCode1 ? item.categoryCode1 : ''}}<br>{{item.categorySuitability1 ? item.categorySuitability1 : ''}}</template>
						<!-- <template v-slot:categoryCode2="{item}">{{item.categoryCode2 ? item.categoryCode2 : ''}}<br>{{item.categorySuitability2 ? item.categorySuitability2 : ''}}</template>
						<template v-slot:categoryCode3="{item}">{{item.categoryCode3 ? item.categoryCode3 : ''}}<br>{{item.categorySuitability3 ? item.categorySuitability3 : ''}}</template> -->
						<template v-slot:maxSalesPrice="{item}">{{item.maxSalesPrice ? parseInt(item.maxSalesPrice).toLocaleString() + "~" + (parseInt(item.maxSalesPrice)+1000).toLocaleString() : item.maxSalesPrice}}</template>
						<template v-slot:maxSales="{item}">{{item.maxSales ? item.maxSales.toLocaleString() : item.maxSales}}</template>
						<template v-slot:middleSales="{item}">{{item.middleSales ? item.middleSales.toLocaleString() : item.middleSales}}</template>
						<template v-slot:sales="{item}">{{item.sales ? item.sales.toLocaleString() : item.sales}}</template>
						
						<template v-slot:n1psales="{item}">{{item.n1psales ? item.n1psales.toLocaleString() : item.n1psales}}</template>	

						<template v-slot:rocketqty="{item}">{{item.rocketqty ? item.rocketqty.toLocaleString() : item.rocketqty}}</template>
						<template v-slot:totalqty="{item}">{{item.totalqty ? item.totalqty.toLocaleString() : item.totalqty}}</template>
						
						<template v-slot:review1phigh="{item}">{{item.review1phigh ? item.review1phigh.toLocaleString() : item.review1phigh}}</template>
						<template v-slot:review1p="{item}">{{item.review1p ? item.review1p.toLocaleString() : item.review1p}}</template>
						<template v-slot:review1pmiddle="{item}">{{item.review1pmiddle ? item.review1pmiddle.toLocaleString() : item.review1pmiddle}}</template>
						<template v-slot:review1plow="{item}">{{item.review1plow ? item.review1plow.toLocaleString() : item.review1plow}}</template>
						
						
						<template v-slot:price1phigh="{item}">{{item.price1phigh ? item.price1phigh.toLocaleString() : item.price1phigh}}</template>
						<template v-slot:price1p="{item}">{{item.price1p ? item.price1p.toLocaleString() : item.price1p}}</template>
						<template v-slot:price1pmiddle="{item}">{{item.price1pmiddle ? item.price1pmiddle.toLocaleString() : item.price1pmiddle}}</template>
						<template v-slot:price1plow="{item}">{{item.price1plow ? item.price1plow.toLocaleString() : item.price1plow}}</template>

						<template v-slot:pricen1phigh="{item}">{{item.pricen1phigh ? item.pricen1phigh.toLocaleString() : item.pricen1phigh}}</template>
						<template v-slot:pricen1p="{item}">{{item.pricen1p ? item.pricen1p.toLocaleString() : item.pricen1p}}</template>
						<template v-slot:pricen1pmiddle="{item}">{{item.pricen1pmiddle ? item.pricen1pmiddle.toLocaleString() : item.pricen1pmiddle}}</template>
						<template v-slot:pricen1plow="{item}">{{item.pricen1plow ? item.pricen1plow.toLocaleString() : item.pricen1plow}}</template>

						<template v-slot:n1psalesqtyhigh="{item}">{{item.n1psalesqtyhigh ? item.n1psalesqtyhigh.toLocaleString() : item.n1psalesqtyhigh}}</template>
						<template v-slot:n1psalesqty="{item}">{{item.n1psalesqty ? item.n1psalesqty.toLocaleString() : item.n1psalesqty}}</template>
						<template v-slot:n1psalesqtymiddle="{item}">{{item.n1psalesqtymiddle ? item.n1psalesqtymiddle.toLocaleString() : item.n1psalesqtymiddle}}</template>
						<template v-slot:n1psalesqtylow="{item}">{{item.n1psalesqtylow ? item.n1psalesqtylow.toLocaleString() : item.n1psalesqtylow}}</template>
						
						<template v-slot:n1psaleshigh="{item}">{{item.n1psaleshigh ? item.n1psaleshigh.toLocaleString() : item.n1psaleshigh}}</template>
						<template v-slot:n1psalesmiddle="{item}">{{item.n1psalesmiddle ? item.n1psalesmiddle.toLocaleString() : item.n1psalesmiddle}}</template>
						<template v-slot:n1psaleslow="{item}">{{item.n1psaleslow ? item.n1psaleslow.toLocaleString() : item.n1psaleslow}}</template>
					</CDataTable>
					<button type="button" class="btn btn-success mt-2 mr-2 mb-4" @click="deleteitem">선택삭제</button> 
					<button type="button" class="btn btn-primary mt-2 mr-2 mb-4" @click="download">다운로드</button>
					<button type="button" class="btn btn-warning mt-2 mr-2 mb-4" @click="addRank">랭킹추적</button>              
					<!-- <button type="button" class="btn btn-primary mr-2 mb-4" v-b-toggle="'my-dropdown'">카테고리적합도</button>      
					<b-collapse id="my-dropdown">
						<b-list-group>
							<b-list-group-item
							v-for="option in categoryOptions"
							:key="option"
							@click="selectOption(option)"
							>
							{{ option }}
							</b-list-group-item>
						</b-list-group>
					</b-collapse> -->
					


					<!-- <button type="button" class="btn btn-secondary mr-2 mb-4" @click="settinglist">목록설정</button>                       -->
					
				</div>
			</card>
		</div>
		<!-- Page end  -->
	</div>
</template>
<script>
import axios from 'axios';
import * as XLSX from 'xlsx';
import ApexChart from '../../../components/charts/ApexChart';
import CDataTable from "@/components/common/CDataTable";
// import { chrome } from 'vue-chrome-extension';
export default {
	name:'nomadKeywordAnalysis',
	components:{
        ApexChart,
		CDataTable
    },
	data(){
		return{
			productList:[],
			selectedProductItem:[],
			marketAbilityHeaders:[
				{text: '노출ID', value: '노출ID', align: 'center', width: 100, isSlot: false},
				{text: '뱃지', value: '뱃지', align: 'center', width: 100, isSlot: true},
				{text: '상품명', value: '상품명', align: 'center', width: 200, isSlot: false},
				{text: '판매링크', value: '판매링크', align: 'center', width: 100, isSlot: true},
				{text: '판매가', value: '판매가', align: 'center', width: 100, isSlot: false},
				{text: '리뷰수', value: '리뷰수', align: 'center', width: 100, isSlot: false},
				{text: '리뷰평점', value: '리뷰평점', align: 'center', width: 100, isSlot: false},
				{text: '조회수', value: '조회수', align: 'center', width: 100, isSlot: false},
				{text: '판매수', value: '판매수', align: 'center', width: 100, isSlot: false},
				{text: '상대\nCTR', value: '상대CTR', align: 'center', width: 100, isSlot: false},
				{text: '예상\nCVR', value: '예상CVR', align: 'center', width: 100, isSlot: false},
				{text: '상대\nMarketability', value: '상대Marketability', align: 'center', width: 100, isSlot: false},
			],
			headers: [
                {text: '쿠팡\n키워드', value: 'targetKeyword', align: 'center', width: 100, isSlot: false},
				{text: '카테고리\n적합도', value: 'category', align: 'center', width: 100, isSlot: true},
				{text: '카테고리\n적합도1', value: 'categoryCode1', align: 'center', width: 100, isSlot: true},
				// {text: '카테고리\n적합도2', value: 'categoryCode2', align: 'center', width: 100, isSlot: true},
				// {text: '카테고리\n적합도3', value: 'categoryCode3', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n평균조회수', value: 'impressions', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n평균전환율', value: 'cvr', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n최적판매가', value: 'maxSalesPrice', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n최대매출', value: 'maxSales', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n중간매출', value: 'middleSales', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n평균매출', value: 'sales', align: 'center', width: 100, isSlot: true},

				{text: '네이버\n평균매출', value: 'n1psales', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p윙비율', value: 'wingrate', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n1p윙상품수', value: 'wing1p', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n1p로켓상품수', value: 'rocket1p', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n1p그로스상품수', value: 'growth1p', align: 'center', width: 100, isSlot: false},

				{text: '쿠팡\n로켓비율', value: 'rocketrate', align: 'center', width: 100, isSlot: false},
				{text: '쿠팡\n로켓상품수', value: 'rocketqty', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n전체상품수', value: 'totalqty', align: 'center', width: 100, isSlot: true},

				{text: '쿠팡\n1p최대리뷰수', value: 'review1phigh', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p평균리뷰수', value: 'review1p', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p중간리뷰수', value: 'review1pmiddle', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p최저리뷰수', value: 'review1plow', align: 'center', width: 100, isSlot: true},

				{text: '쿠팡\n1p최대판매가', value: 'price1phigh', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p평균판매가', value: 'price1p', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p중간판매수', value: 'price1pmiddle', align: 'center', width: 100, isSlot: true},
				{text: '쿠팡\n1p최저판매수', value: 'price1plow', align: 'center', width: 100, isSlot: true},

				{text: '네이버\n1p최대판매가', value: 'pricen1phigh', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p평균판매가', value: 'pricen1p', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p중간판매수', value: 'pricen1pmiddle', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p최저판매수', value: 'pricen1plow', align: 'center', width: 100, isSlot: true},

				{text: '네이버\n1p최대월판매수', value: 'n1psalesqtyhigh', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p평균월판매수', value: 'n1psalesqty', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p중간월판매수', value: 'n1psalesqtymiddle', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p최저월판매수', value: 'n1psalesqtylow', align: 'center', width: 100, isSlot: true},

				{text: '네이버\n1p최대월매출', value: 'n1psaleshigh', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p평균월매출', value: 'n1psales', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p중간월매출', value: 'n1psalesmiddle', align: 'center', width: 100, isSlot: true},
				{text: '네이버\n1p최저월매출', value: 'n1psaleslow', align: 'center', width: 100, isSlot: true},
            ],
            tableSelectedItem: [],
			delayTime:1000,

			addKeyword : "",
			keywordGroup : ["Default"],
			activeKeyword : "Default",

			showNomadv3 : this.$store.state.user.auth.shownomadv3,

			showKeywordGroup : true,
			categoryCode1st:"",
			categoryCode2nd:"",
			categoryCode3rd:"",

			mainKeywordAll:"",
			mainKeyword1st:"",
			mainKeyword2nd:"",
			mainKeyword3rd:"",

			AllSum:"",
			categoryCode1stSum:"",
			categoryCode2ndSum:"",
			categoryCode3rdSum:"",
			targetPurchase:"",
			targetPrice:"",
			daySales:"",
			targetKeyword1st:"",
			targetRank:"",
			targetClick:"",
			targetCVR:"",
			targetStockPeriod:30,
			correction:50,
			purchaseQty:"",
			targetKeywordList:[],
			currentIndexTargetKeyword:0,


			showuploadspinner:false,
			uploadstatus:'',
			showAutoList:false,
			autoList:[],
			checkalllists:false,
			showKeywordList:false,
			keywordAnalysisEnable:true,
			analysisType:'keywordSales',
			salesUnit:'daily',
			relatedTargetKeyword:[],
			auth:'',
			loadingtext:'연관검색어 분석 중',
			loadingrate:'',
			categoryOptions: [], // 이 배열을 카테고리적합도 옵션으로 채워야 함
			showspinner:false,
			showlist:false,

			waitresult:false,
			targetKeyword:'',
			fullsearch:false,
			addlines: 10,
			rowlines:[],

			keywordList:[],

			title:"키워드 그래프 분석",
			desc1:"키워드를 분석할 때마다 결과가 아래로 쌓이며, 대량수집 설정에서 연관키워드를 업로드하거나 추가하면 대량 수집도 가능합니다.",
			desc2:"판매량과 월조회수를 선택해서 조회할 수 있습니다. 많은 데이터 수집이 필요하므로 ip차단에 유의해 주세요.",
			desc3:"",
			monthSeries:[],
			dailySeries:[],
			chart:{
				series: [
				],
				chart: {
					type: 'line',
					height: 500,
					width: 1200,
					animations: {
						enabled: false // 애니메이션 비활성화
					}
				},
				dataLabels: {
					enabled: false
				},
				stroke: {
					show: true,
					width: 3,
				},
				xaxis: {
					categories: [],
				},
				fill: {
					opacity: 1
				},
				tooltip: {
					shared: true, 
					intersect: false,
					y: {
						formatter: function (val) {
							return val ? val.toLocaleString() + " 원" : val
						}
					}
				}
			}
		}
	},	
	watch: {
		tableSelectedItem : 'checkChart'
	},
	beforeDestroy() {
		window.removeEventListener('message', this.messageEventHandler);
	},	
	mounted(){
		console.log(navigator)
		this.messageEventHandler = async(event) => {
			if(event.data.res && event.data.res === 'login'){
				alert('쿠팡wing에 로그인 해주세요')
				this.showspinner = false;
				// this.showlist = false;
				// this.showKeywordList = false;
				return
			}
			if(event.data.res && event.data.res === 'keywordanalysisstatus'){
                console.log(event.data.status);
				this.showspinner = true;
				this.loadingtext = '키워드 별 페이지 분석 중';
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
				
				// this.showlist = false;
				// this.showKeywordList = false;
				// var obj = event.data.result;
				// this.keywordList[obj.index].rank = obj.rank;
				// this.keywordList[obj.index].adqty = obj.adqty;
				// this.keywordList[obj.index].adplace = obj.adplace;
				// this.keywordList[obj.index].productqty = obj.productqty;
				// this.keywordList[obj.index].rocketqty = obj.rocketqty;
				// this.keywordList[obj.index].totalrank = obj.totalrank;
				// this.waitresult = false;
				// await this.sleep(10);
				// this.showspinner = false;
				// this.showlist = true;
				return
            }
			if(event.data.res && event.data.res === 'keywordanalysisstatusratio'){
                console.log(event.data.status);
				this.showspinner = true;
				this.loadingtext = event.data.text;
				this.loadingrate = '(' + (event.data.status*100).toFixed(0) + "%)";
				return
            }
			if(event.data.res && event.data.res === 'addRankSuccess'){
                alert('랭킹 추적 추가 완료')
				this.showspinner = false;
				return
            }
            if(event.data.res && event.data.res === 'keywordanalysisNomad'){
				// if(!event.data.cpc){
				// 	alert('CPC단가는 쿠팡wing에 로그인 후 정상적으로 출력됩니다.')
				// }
				console.log(event.data)
                console.log(event.data.coupang);
				console.log(event.data.naver);
				console.log(event.data.coupangSales);
				
				this.loadingtext = '데이터 계산 중';
				this.loadingrate = "";
				this.showspinner = true;
				this.showlist = false;
				this.showKeywordList = false;
				
				
				this.makeKeywordList(event.data);
				console.log(this.autoList)
				this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:this.autoList})
				console.log(this.$store.state.keywordList)
				this.showKeywordResult();
				return
            }
            if(event.data.res && event.data.res === 'relatedkeywordsTF2'){
                console.log(event.data.relatedkeywords)
				var arr = event.data.relatedkeywords;
				var ar = [];

				arr.forEach(ele => {
					if(!ar.includes(ele)){
						ar.push(ele)
					}
				})
				var keywords = [];
				// console.log(ar)
				ar.forEach(ele => {
					if(this.autoList.length == 0 || (!this.autoList.find(element => element.targetKeyword == ele) && this.autoList.length <= 100)){
						keywords.push(ele);
					}
				})
				// console.log(keywords)
				// this.keywordList = [];
				var addlist = [];
				for(var i=0;i<keywords.length;i++){
					if(this.autoList.length == 0 || (!this.autoList.find(f => f.targetKeyword == keywords[i]) && this.autoList.length <= 100)){
						if(this.activeKeyword == 'Default'){
							this.autoList.push({
								targetKeyword:keywords[i],
								companyid:this.$store.state.user.companyid,
							})
							addlist.push({
								targetKeyword:keywords[i],
								companyid:this.$store.state.user.companyid
							})
						} else {
							this.autoList.push({
								targetKeyword:keywords[i],
								companyid:this.$store.state.user.companyid,
								keywordGroup:this.activeKeyword
							})
							addlist.push({
								targetKeyword:keywords[i],
								companyid:this.$store.state.user.companyid,
								keywordGroup:this.activeKeyword
							})
						}

					}
				}

				if(this.activeKeyword == 'Default'){
					addlist.push({
						targetKeyword:this.relatedTargetKeyword,
						companyid:this.$store.state.user.companyid,
						연관키워드 : true
					})
				} else {
					addlist.push({
						targetKeyword:this.relatedTargetKeyword,
						companyid:this.$store.state.user.companyid,
						연관키워드 : true,
						keywordGroup : this.activeKeyword
					})
				}



				if(this.autoList.length >= 100){
					alert('키워드는 최대 100개까지 수집 가능합니다.')
				}
				this.updateAddList(addlist);
				this.showspinner = false;
				// this.importresults(keywords)
				return
            }
		}
		window.addEventListener('message', this.messageEventHandler);
		this.getauthNo()
		this.startfocus();
		this.showResult();
	},
	methods: {
		prevKeyword(){
			if(this.targetKeywordList.length == 0){
				return
			}
			if(this.currentIndexTargetKeyword == 0){
				return
			}
			this.currentIndexTargetKeyword--
			this.targetKeyword1st = this.targetKeywordList[this.currentIndexTargetKeyword].targetKeyword;
			this.targetRank = this.targetKeywordList[this.currentIndexTargetKeyword].rank;
			this.targetClick = this.targetKeywordList[this.currentIndexTargetKeyword].click;
			if(this.targetClick && this.targetCVR){
				if(this.targetStockPeriod){
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod)
					}
				} else {
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01)
					}
				}
			}
		},
		nextKeyword(){
			if(this.targetKeywordList.length == 0){
				return
			}
			if(this.currentIndexTargetKeyword == this.targetKeywordList.length - 1){
				return
			}
			this.currentIndexTargetKeyword++
			this.targetKeyword1st = this.targetKeywordList[this.currentIndexTargetKeyword].targetKeyword;
			this.targetRank = this.targetKeywordList[this.currentIndexTargetKeyword].rank;
			this.targetClick = this.targetKeywordList[this.currentIndexTargetKeyword].click;
			if(this.targetClick && this.targetCVR){
				if(this.targetStockPeriod){
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod)
					}
				} else {
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01)
					}
				}
			}
		},
		initPurchase(){
			this.targetPurchase = "";
			this.targetPrice="";
			this.daySales="";
			this.targetKeyword1st="";
			this.targetRank="";
			this.targetClick="";
			this.targetCVR="";
			this.purchaseQty="";
			this.targetKeywordList=[];
			this.currentIndexTargetKeyword=0;
		},
		isUniform(arr, key) {
			if (arr.length === 0) {
				return true; // 빈 배열은 uniform으로 간주
			}
			const firstValue = arr[0][key]; // 첫 번째 객체의 키 값
			return arr.every(obj => obj[key] === firstValue); // 모든 객체의 키 값이 첫 번째 값과 같은지 확인
		},
		calPurchase(){
			if(this.tableSelectedItem.length == 0 || !this.isUniform(this.tableSelectedItem, 'categoryCode1')){
				alert('상단에서 분석할 카테고리순위를 선택해 주세요.')
				this.initPurchase();
				return
			}
			if(this.targetPurchase && this.targetPrice){
				this.daySales = this.targetPurchase*this.targetPrice;
			}
			if(this.daySales){
				var arrSales = [];
				for(var e of this.tableSelectedItem){
					var idxSales = e.chartData.sort((a,b)=>{return b.monthSales - a.monthSales}).findIndex(f => f.monthSales <= this.daySales*30);
					var click = parseInt(e.chartData.sort((a,b)=>{return b.impressions - a.impressions})[idxSales].impressions/28);
					arrSales.push({
						targetKeyword:e.targetKeyword + "("+ e.categorySuitability1 + ")",
						rank:idxSales+1,
						click:click,
						salesDev:this.daySales*30-e.chartData.sort((a,b)=>{return b.monthSales - a.monthSales})[idxSales].monthSales
					})
				}
				arrSales.sort((a,b)=>{return a.rank == b.rank ? a.salesDev-b.salesDev : a.rank - b.rank});
				console.log(arrSales)
				this.targetKeywordList = arrSales;
				this.currentIndexTargetKeyword = 0;
				this.targetKeyword1st = this.targetKeywordList[this.currentIndexTargetKeyword].targetKeyword;
				this.targetRank = this.targetKeywordList[this.currentIndexTargetKeyword].rank;
				this.targetClick = this.targetKeywordList[this.currentIndexTargetKeyword].click;
			}
			this.changePurchase();
		},
		changePurchase(){
			if(this.targetClick && this.targetCVR){
				if(this.targetStockPeriod){
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.targetStockPeriod)
					}
				} else {
					if(this.correction){
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01*this.correction*0.01)
					} else {
						this.purchaseQty = parseInt(this.targetClick*this.targetCVR*0.01)
					}
				}
			}
		},
		showAll(){
			this.tableSelectedItem = this.keywordList.filter(e => e.categoryCode1 == this.categoryCode1st)
			this.calPurchase()
		},
		show1st(){
			this.tableSelectedItem = this.keywordList.filter(e => e.categoryCode1 == this.categoryCode1st)
			this.calPurchase()
		},
		show2nd(){
			this.tableSelectedItem = this.keywordList.filter(e => e.categoryCode1 == this.categoryCode1st)
			this.calPurchase()
		},
		show3rd(){
			this.tableSelectedItem = this.keywordList.filter(e => e.categoryCode1 == this.categoryCode1st)
			this.calPurchase()
		},
		addRank(){
			console.log(this.tableSelectedItem)
			if(this.tableSelectedItem.length == 0){
				alert('랭킹 추적에 추가할 키워드를 선택해 주세요');
				return
			}
			if(!confirm(this.tableSelectedItem.length + '개의 키워드를 랭킹 추적에 추가하시겠습니까?')){
				return
			}
			var productlink = prompt('랭킹 추적할 쿠팡상품 링크를 입력해 주세요');
			if(!productlink){
				return
			}
			if(!productlink.includes('www.coupang.com/vp/products/')){
				alert('랭킹 추적할 쿠팡상품 링크를 올바르게 입력해 주세요.')
				return
			}
			window.postMessage({greeting: "coupangrank",link:productlink, keywords:this.tableSelectedItem.map(e => e.targetKeyword), companyid:this.$store.state.user.companyid},"*",)
		},
		addRelKeywordEnter(event){
			if(event.keyCode == '13'){
				this.addRelKeyword();
			}
		},
		addRelKeyword(){
			if(this.autoList.length >= 100){
				alert('수집할 수 있는 키워드 수를 초과하였습니다. 다른 키워드 제거 후 추가해 주세요.')
				return 
			}
			if(!this.addKeyword){
				alert('추가할 키워드를 입력해 주세요')
				return
			}
			if(this.autoList.includes(this.addKeyword)){
				alert('이미 추가된 키워드입니다.')
				return
			}
			
			this.showAutoList = false;
			
			var addList = [];
			if(this.activeKeyword == "Default"){
				this.autoList.push({
					targetKeyword:this.addKeyword,
					companyid:this.$store.state.user.companyid
				})
				addList.push({
					targetKeyword:this.addKeyword,
					companyid:this.$store.state.user.companyid
				})
			} else {
				this.autoList.push({
					targetKeyword:this.addKeyword,
					companyid:this.$store.state.user.companyid,
					keywordGroup:this.activeKeyword
				})
				addList.push({
					targetKeyword:this.addKeyword,
					companyid:this.$store.state.user.companyid,
					keywordGroup:this.activeKeyword
				})
			}
			this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:this.autoList})
			this.updateAddList(addList)
			setTimeout(() => {
				this.showAutoList = true;
				this.addKeyword = "";
			}, 10);
		},
		async deleteKeywordGroup(keyword){
			// console.log(this.$store.state.keywordList)
			if(keyword == 'Default'){
				alert('Default 키워드그룹은 삭제가 불가합니다.')
				return
			}
			if(this.showspinner){
				alert('수집중인 상태에서는 삭제가 불가능합니다.')
				return
			}
			if(!confirm('"' + keyword + '" 키워드그룹을 삭제하시겠습니까?')){
				return
			}
			try{
				var result = await axios.post('/api/nomad/deleteKeywordAnalysisNomad',this.$store.state.keywordList.filter(e => e.keywordGroup == keyword))
				if(result.data.res == 'OK'){
					this.$store.commit('updateKeywordList',{keyword:keyword,arr:[]});
					this.keywordGroup = this.keywordGroup.filter(e => e !== keyword);
					this.setActiveKeyword("Default");
					alert('삭제완료')
					
				} else {
					alert('삭제실패(600)')
					return
				}
			} catch(e){
				console.log(e)
			}
			console.log(keyword)
		},
		createKeywordGroup(){
			var keywordGroupName = prompt('키워드 그룹명을 설정해 주세요');
			if(!keywordGroupName){
				return
			}
			if(this.keywordGroup.includes(keywordGroupName)){
				alert('이미 사용중인 키워드 그룹명입니다.')
				return
			}
			this.keywordGroup.push(keywordGroupName);
			this.activeKeyword = keywordGroupName;
			this.showspinner = true;
			this.showlist = false;
			this.showKeywordList = false;
			this.autoList = [];
			this.keywordList = [];
			this.targetKeyword = "";
			this.showKeywordResult();
		},
		setActiveKeyword(keyword){
			if(this.showspinner){
				alert('수집중인 상태에서는 키워드 그룹 이동이 불가능합니다.')
				return
			}
			if(keyword == this.activeKeyword){
				return
			}
			this.showspinner = true;
			this.showlist = false;
			this.showKeywordList = false;
			if(keyword == 'Default'){
				this.autoList = this.$store.state.keywordList.filter(e => !e.keywordGroup);
			} else {
				this.autoList = this.$store.state.keywordList.filter(e => e.keywordGroup && e.keywordGroup == keyword);
			}
			this.activeKeyword = keyword;
			this.targetKeyword = "";
			console.log(this.autoList)
			this.showKeywordResult();
		},
		importRel(list){
			this.relatedTargetKeyword = list.targetKeyword;
			list.연관키워드 = true;
			window.postMessage({greeting: "getrelatedkeywordsTF2",data:list.targetKeyword},"*",)
			this.loadingtext = '연관검색어 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
		},
		keywordClass(chartData){
			if(chartData){
				return "badge bg-success"
			} else {
				return "badge bg-secondary"
			}
		},
		uploadfile(event) {
            this.showAutoList = false;
			this.showuploadspinner = true;
			this.uploadstatus = 'uploading...';
            // var 주문번호,판매처,배송단가,할인단가,운송장번호,주문날짜,주문상태;
            setTimeout(() => {
                const file = event.target.files[0];
                let reader = new FileReader();
                reader.onload = (e) => {
                    // let data = reader.result;
                    let data = e.target.result;
                    let workbook = XLSX.read(data, {type: 'binary'});
                    workbook.SheetNames.forEach(sheetName => {
                        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
                        console.log(roa)
						var addList = [];
						for(var e of roa){
							if(this.autoList.length == 0 || (!this.autoList.find(f => f.targetKeyword == e.키워드) && this.autoList.length <= 100)){
								if(this.activeKeyword == "Default"){
									this.autoList.push({
										targetKeyword:e.키워드,
										companyid:this.$store.state.user.companyid
									})
									addList.push({
										targetKeyword:e.키워드,
										companyid:this.$store.state.user.companyid
									})
								} else {
									this.autoList.push({
										targetKeyword:e.키워드,
										companyid:this.$store.state.user.companyid,
										keywordGroup:this.activeKeyword
									})
									addList.push({
										targetKeyword:e.키워드,
										companyid:this.$store.state.user.companyid,
										keywordGroup:this.activeKeyword
									})
								}
							}
						}
						this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:this.autoList})
						if(this.autoList.length >= 100){
							alert('키워드는 최대 100개까지 수집 가능합니다.')
						}
						this.updateAddList(addList)
                        this.showAutoList = true;
						this.uploadstatus = 'success';
						this.showuploadspinner = false;
                    });
                };
                reader.readAsBinaryString(file);
            }, 10);
        },
		excelDownFunc(){
            var arr = [];
            var myHeader = ["키워드"];
            var dataWS = XLSX.utils.json_to_sheet(arr, {header : myHeader});
            const range = XLSX.utils.decode_range(dataWS['!ref']);
            range.e['c'] = myHeader.length - 1;
            dataWS['!ref'] = XLSX.utils.encode_range(range);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, dataWS, '키워드업로드양식'); 
            // 엑셀 파일을 내보낸다.
            XLSX.writeFile(wb, '키워드업로드양식.xlsx');
        },
		updateAddList(addlist){
			if(addlist.length > 0){
				axios.post('/api/nomad/updateKeywordAnalysisNomad',addlist)
				.then(result => {
					if(result.data.res == "OK"){
						console.log(result.data)
					}
				})
			}
		},
		showResult(){
			this.showspinner = true;
			this.showlist = false;
			this.showKeywordList = false;
			this.autoList = [];
			this.keywordList = [];
			this.keywordGroup = ['Default'];
			if(this.$store.state.keywordList.length > 0){
				this.autoList = this.$store.state.keywordList.filter(e => !e.keywordGroup);
				for(var e of this.$store.state.keywordList){
					if(e.keywordGroup && !this.keywordGroup.includes(e.keywordGroup)){
						this.keywordGroup.push(e.keywordGroup)
					}
				}
				console.log(this.autoList)
				this.showKeywordResult();
				// this.showProductList();
				return
			}
			axios.post('/api/nomad/getKeywordAnalysisNomad',{companyid:this.$store.state.user.companyid})
			.then(result => {
                if(result.data.length > 0){
					this.$store.commit('setKeywordList',result.data)
					this.autoList = this.$store.state.keywordList.filter(e => !e.keywordGroup);
					for(var e of this.$store.state.keywordList){
						if(e.keywordGroup && !this.keywordGroup.includes(e.keywordGroup)){
							this.keywordGroup.push(e.keywordGroup)
						}
					}
					this.showKeywordResult()
					// this.showProductList();
                } else {
                    alert('분석된 키워드 리스트가 없습니다.')
					this.showspinner = false;
                    return
                }
			})
		},
		changeAnalysisType(){
			this.loadingtext = '';
			this.loadingrate = "";
			this.showlist = false;
			this.showspinner = true;
			this.showKeywordResult();
			// this.showProductList();
		},
		showProductList(){
			if(this.keywordList.length == 0){
				return
			}
			var arr = [];
			for(var e of this.keywordList){
				for(var f of e.chartData){
					if(!arr.find(ele => ele.노출ID == f.productId)){
						if(f.product.type == 'wing'){
							var badge = ''
						}
						if(f.product.type == 'jet'){
							badge = 'https://image7.coupangcdn.com/image/coupang/rds/logo/iphone_2x/logoRocketMerchantLargeV3R3@2x.png'
						}
						if(f.product.type == 'rocket'){
							badge = 'https://image6.coupangcdn.com/image/cmg/icon/ios/logo_rocket_large@3x.png'
						}
						arr.push({
							노출ID : f.productId,
							뱃지 : badge,
							판매링크 : "https://www.coupang.com/vp/products/" + f.productId,
							// 상품이미지 : f.product.imgUrl,
							상품명 : f.product.productName,
							판매가 : f.product.price,
							리뷰수 : f.product.ratingcount,
							리뷰평점 : f.product.rating,
							조회수 : f.impressions,
							판매수 : f.monthQty,
							예상CVR : Math.round((f.monthQty/30)/(f.impressions/28)*100)/100
						})
					}
				}
			}

			arr.sort((a,b)=>{return b.조회수 - a.조회수})
			for(var i=0;i<arr.length;i++){
				arr[i].순위 = i+1;
				arr[i].상대Marketability = arr[i].조회수 > 0 ? (i+1)*arr[i].판매수 : 0;
				arr[i].상대CTR = arr[i].예상CVR ? arr[i].상대Marketability / arr[i].예상CVR : 0;
			}
			arr.sort((a,b) => {return b.상대Marketability - a.상대Marketability})
			this.productList = arr;
		},
		showKeywordResult(){
			if(this.autoList.length == 0){
				setTimeout(()=>{
					this.showlist = false;
					this.showspinner = false;
					this.showKeywordList = false;
					this.showAutoList = false;
				},110)
				return
			}
			this.keywordList = this.autoList.filter(e => e.chartData);
			this.tableSelectedItem = this.autoList.filter(e => e.chartData);
			console.log(this.keywordList.length)
			if(this.keywordList.length == 0){
				this.addChart([])
				setTimeout(()=>{
					this.showlist = false;
					this.showspinner = false;
					this.showKeywordList = false;
					this.showAutoList = true;
				},110)
				return
			}
			this.showProductList()

			for(var i=0;i<this.keywordList.length;i++){
				this.calculateMetricsByPriceRange(this.keywordList[i].chartData)
			}

			var arr = []
			var prevSales = 0;
			var mainKeywordAll = "";
			var allSum = 0;
			for(var e of this.keywordList){
				if(prevSales < e.sales){
					prevSales = e.sales;
					mainKeywordAll = e.targetKeyword;
				}
				allSum += e.sales;
				if(arr.length > 0 && arr.find(f => f.categoryCode == e.categoryCode1)){
					arr.find(f => f.categoryCode == e.categoryCode1).cnt++
					if(arr.find(f => f.categoryCode == e.categoryCode1).sales < e.sales){
						arr.find(f => f.categoryCode == e.categoryCode1).mainKeyword = e.targetKeyword;
					}
					arr.find(f => f.categoryCode == e.categoryCode1).sales += e.sales;
					
				} else {
					arr.push({
						categoryCode : e.categoryCode1,
						cnt : 1,
						sales : e.sales,
						mainKeyword : e.targetKeyword
					})
				}
			}
			arr.sort((a,b) => {return b.sales - a.sales});
			console.log(arr)

			this.categoryCode1st = arr[0] ? arr[0].categoryCode : "";
			this.categoryCode2nd = arr[1] ? arr[1].categoryCode : "";
			this.categoryCode3rd = arr[2] ? arr[2].categoryCode : "";

			this.categoryCode1stSum = arr[0] ? arr[0].sales : "";
			this.categoryCode2ndSum = arr[1] ? arr[1].sales : "";
			this.categoryCode3rdSum = arr[2] ? arr[2].sales : "";

			this.mainKeyword1st = arr[0] ? arr[0].mainKeyword : "";
			this.mainKeyword2nd = arr[1] ? arr[1].mainKeyword : "";
			this.mainKeyword3rd = arr[2] ? arr[2].mainKeyword : "";

			this.AllSum = allSum;
			this.mainKeywordAll = mainKeywordAll;

			var chartData = this.tableSelectedItem.map(e => e.chartData);
			console.log(chartData)
			this.addChart(chartData);
			this.changeSalesUnit();
			// this.categoryOptionsListing();
			setTimeout(()=>{
				this.showlist = true;
				this.showspinner = false;
				this.showKeywordList = true;
				this.showAutoList = true;
			},100)
		},
		// categoryOptionsListing(){
		// 	var arr = this.keywordList.filter(e => e.categoryCode1);
		// 	this.categoryOptions = [];
		// 	arr.forEach(e => {
		// 		if(!this.categoryOptions.includes(e.categoryCode1)){
		// 			this.categoryOptions.push(e.categoryCode1);
		// 		}
		// 	})
		// },
		// selectOption(option){
		// 	this.showlist = false;
		// 	this.keywordList = this.keywordList.filter(e => e.categoryCode1 == option);
		// 	var chartData = this.keywordList.filter(e => e.checked).map(e => e.chartData);
		// 	console.log(chartData)
		// 	this.addChart(chartData);
		// 	this.changeSalesUnit();
		// 	this.categoryOptionsListing();
		// 	setTimeout(()=>{
		// 		this.showlist = true;
		// 		this.showspinner = false;
		// 		this.showKeywordList = true;
		// 		this.showAutoList = true;
		// 	},100)
		// },
		calculateMetricsByPriceRange(products) {
			let metricsByPriceRange = {};

			// 판매량과 조회수를 가격대별로 집계합니다.
			products.forEach(product => {
				let priceRange = Math.floor(product.price / 1000) * 1000;
				let priceKey = `${priceRange}-${priceRange + 999}`;

				if (!metricsByPriceRange[priceKey]) {
					metricsByPriceRange[priceKey] = { sales: 0, impressions: 0 };
				}
				metricsByPriceRange[priceKey].sales += product.monthQty;
				metricsByPriceRange[priceKey].impressions += product.impressions;
			});

			// 결과를 배열 형태로 변환합니다.
			console.log(Object.keys(metricsByPriceRange).map(priceRange => ({
				priceRange,
				...metricsByPriceRange[priceRange]
			})))
		},
		checkChart(){
			this.showlist = false;
			var chartData = this.tableSelectedItem.map(e => e.chartData);
			console.log(chartData)
			this.addChart(chartData);
			this.changeSalesUnit();
			// this.categoryOptionsListing();
			setTimeout(()=>{
				this.showlist = true;
			},100)
		},
		// checkalllist(){
		// 	if(this.checkalllists){
		// 		this.keywordList.forEach(e => {e.checked = true})
		// 	} else {
		// 		this.keywordList.forEach(e => {e.checked = false})
		// 	}
		// 	this.showlist = false;
		// 	var chartData = this.keywordList.filter(e => e.checked).map(e => e.chartData);
		// 	console.log(chartData)
		// 	this.addChart(chartData);
		// 	this.changeSalesUnit();
		// 	// this.categoryOptionsListing();
		// 	setTimeout(()=>{
		// 		this.showlist = true;
		// 	},100)
		// },
		listTopCategoryIds(coupang) {
			// 입력값이 배열인지 확인합니다.
			if (!Array.isArray(coupang)) {
				console.error('Invalid input: coupang is not an array.');
				return [];
			}

			const categoryIdCounts = coupang.reduce((acc, item) => {
				// item.product 또는 item.product.leafCategoryInfo가 undefined가 아닌지 확인합니다.
				if (item.product && item.product.leafCategoryInfo) {
				const categoryId = item.product.leafCategoryInfo.categoryId;
				if (acc[categoryId]) {
					acc[categoryId]++;
				} else {
					acc[categoryId] = 1;
				}
				}
				return acc;
			}, {});

			const sortedCategoryIds = Object.keys(categoryIdCounts).map(categoryId => ({
				categoryId,
				count: categoryIdCounts[categoryId]
			})).sort((a, b) => b.count - a.count);

			const totalProducts = coupang.length;

			const topCategories = sortedCategoryIds.slice(0, 3).map(item => ({
				categoryId: item.categoryId,
				share: ((item.count / totalProducts) * 100).toFixed(2) + '%'
			}));

			return topCategories;
		},
		makeKeywordList(data){
			var coupang = data.coupang;
			var naver = data.naver;
			var coupangSales = data.coupangSales;
			var category = data.category;

			var addlist = []
			for(var e of this.autoList){
				if(coupang.find(ele => ele.keyword == e.targetKeyword)){
					var coupang1p = coupang.find(ele => ele.keyword == e.targetKeyword);
					var coupang1plist = coupang1p.productlists1p.filter(ele => ele.ad == false);
					// console.log(coupang1plist.map(ele => ele.ratingcount))
					var naver1p = naver.find(ele => ele.keyword == e.targetKeyword).keywordvalue;
					var naverlist = naver.find(ele => ele.keyword == e.targetKeyword).products;
					// console.log(naverlist.filter(ele => ele.purchaseCnt >= 0))
					for(var j=0;j<naver1p.length;j++){
						naver1p[j].long = parseInt(naver1p[j].long > naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/6 ? naver1p[j].long : naverlist.find(ele => ele.rank == naver1p[j].rank).purchaseCnt/6);
					}
					// var cpc = cpcobj ? cpcobj[e.targetKeyword] : '';
					
					e.category = category.category;
					e.categorySuitability = category.rate;
					// var arr = this.listTopCategoryIds(coupang1plist);
					// e.categoryCode1 = arr[0] ? arr[0].categoryId : "";
					// e.categoryCode2 = arr[1] ? arr[1].categoryId : "";
					// e.categoryCode3 = arr[2] ? arr[2].categoryId : "";
					// e.categorySuitability1 = arr[0] ? arr[0].share : "";
					// e.categorySuitability2 = arr[1] ? arr[1].share : "";
					// e.categorySuitability3 = arr[2] ? arr[2].share : "";

					e.wing1p = coupang1plist.filter(ele => ele.type == 'wing').length;
					e.rocket1p = coupang1plist.filter(ele => ele.type == 'rocket').length;
					e.growth1p = coupang1plist.filter(ele => ele.type == 'jet').length;
					e.wingrate = (e.wing1p/(e.wing1p+e.rocket1p+e.growth1p)*100).toFixed(2);
					// e.adqty = coupang1p.adqty;
					// e.adplace = coupang1p.adplace;
					// e.adrate = (coupang1p.adqty/coupang1p.adplace*100).toFixed(2);
					// e.cpchigh = cpc ? cpc.high : 0;
					// e.cpclow = cpc ? cpc.low : 0;
					// e.cpc = cpc ? cpc.suggested : 0;
					e.rocketqty = coupang1p.rocketqty;
					e.totalqty = coupang1p.totalqty;
					e.rocketrate = (coupang1p.rocketqty/coupang1p.totalqty*100).toFixed(2);

					e.review1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.ratingcount ? pv : cv.ratingcount},0);
					e.review1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.ratingcount ? pv : cv.ratingcount},100000000);
					e.review1p = Math.round(coupang1plist.reduce((pv,cv)=>{return pv + cv.ratingcount},0)/coupang1plist.length);
					e.review1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.ratingcount));

					e.price1phigh = coupang1plist.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0);
					e.price1plow = coupang1plist.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},100000000);
					e.price1p = Math.round(coupang1plist.reduce((pv,cv)=>{return pv + cv.price},0)/coupang1plist.length);
					e.price1pmiddle = this.middlelevel(coupang1plist.map(ele => ele.price));

					e.pricen1phigh = naver1p.reduce((pv,cv)=>{return pv > cv.price ? pv : cv.price},0);
					e.pricen1plow = naver1p.reduce((pv,cv)=>{return pv < cv.price ? pv : cv.price},100000000);
					e.pricen1p = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.price},0))/naver1p.length);
					e.pricen1pmiddle = this.middlelevel(naver1p.map(ele => ele.price));
					// e.crate = (e.review1p/e.totalqty).toFixed(2);
					e.n1psalesqtyhigh = naver1p.reduce((pv,cv)=>{return pv > cv.long ? pv : cv.long},0);
					e.n1psalesqtylow = naver1p.reduce((pv,cv)=>{return pv < cv.long ? pv : cv.long},100000000);
					e.n1psalesqty = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.long},0))/naver1p.length);
					e.n1psalesqtymiddle = this.middlelevel(naver1p.map(ele => ele.long));

					e.n1psaleshigh = naver1p.reduce((pv,cv)=>{return pv > cv.long*cv.price ? pv : cv.long*cv.price},0);
					e.n1psaleslow = naver1p.reduce((pv,cv)=>{return pv < cv.long*cv.price ? pv : cv.long*cv.price},100000000);
					e.n1psales = Math.round((naver1p.reduce((pv,cv)=>{return pv + cv.long*cv.price},0))/naver1p.length);
					e.n1psalesmiddle = this.middlelevel(naver1p.map(ele => ele.long*ele.price));
					
					e.chartData = coupangSales.find(ele => ele[0].keyword == e.targetKeyword);

					var saleslist = coupangSales.find(ele => ele[0].keyword == e.targetKeyword);
					e.chartData = saleslist;
					console.log(saleslist)
					console.log(saleslist.sort((a, b)=>{return b.monthSales - a.monthSales}))
					console.log(saleslist.sort((a, b)=>{return b.monthSales - a.monthSales})[Math.round(saleslist.length/2)])

					e.maxSalesPrice = this.findHighestSalesGroup(saleslist);
					e.maxSales = saleslist.reduce((pv, cv)=>{return (pv >= cv.monthSales) ? pv : cv.monthSales},0)
					e.middleSales = saleslist.sort((a, b)=>{return b.monthSales - a.monthSales})[Math.round(saleslist.length/2)].monthSales
					e.sales = Math.round(saleslist.reduce((pv, cv)=>{return pv + cv.monthSales},0)/saleslist.length)
					e.impressions = Math.round(saleslist.reduce((pv, cv)=>{return pv + cv.impressions},0)/saleslist.length)
					// e.cvr = Math.round(((saleslist.reduce((pv, cv)=>{return pv + cv.monthQty},0)/saleslist.length)/30)/(e.impressions/28) * 10000) / 100;
					e.cvr = Math.round((saleslist.reduce((pv, cv)=>{return pv + (cv.impressions ? (cv.monthQty/30)/(cv.impressions/28) : 0)},0)/saleslist.length) * 10000) / 100;
					e.checked = true;

					if(this.activeKeyword !== 'Default'){
						e.keywordGroup = this.activeKeyword;
					}
					addlist.push(this.deepClone(e));
				}
			}
			if(addlist.length > 0){
				this.updateAddList(addlist);
			}
		},
		changeSalesUnit(){
			console.log(this.salesUnit)
			this.showlist = false;
			if(this.salesUnit == 'monthly'){
				this.chart.series = this.monthSeries;
			} else {
				this.chart.series = this.dailySeries;
			}
			setTimeout(()=>{
				this.showlist = true;
			},1)
		},
		findHighestSalesGroup(saleslist) {
			// 매출을 가격대 별로 그룹화하기 위한 객체
			const salesByPriceGroup = {};

			// 배열을 순회하며 각 가격대의 매출을 집계
			saleslist.forEach(item => {
				const price = item.product.price;
				const monthSales = item.monthSales;
				// 가격을 1000원 단위로 그룹화
				const priceGroup = Math.floor(price / 1000) * 1000;

				// 해당 가격대의 매출을 누적
				if (salesByPriceGroup[priceGroup]) {
					salesByPriceGroup[priceGroup] += monthSales;
				} else {
					salesByPriceGroup[priceGroup] = monthSales;
				}
			});

			// 가장 높은 매출을 가진 가격대 찾기
			let maxSales = 0;
			let maxSalesGroup = null;

			for (const [group, sales] of Object.entries(salesByPriceGroup)) {
				if (sales > maxSales) {
					maxSales = sales;
					maxSalesGroup = group;
				}
			}

			// 결과 출력
			console.log(`가장 높은 매출을 가진 가격대: ${maxSalesGroup}원대, 매출액: ${maxSales}원`);
			return maxSalesGroup; // 필요에 따라 반환값 조정 가능
		},
		addChart(s){
			var ylength = s.reduce((pv,cv) => {return pv <= cv.length ? pv : cv.length},100);
			this.monthSeries = [];
			this.dailySeries = [];
			if(this.analysisType == 'keywordSales' || this.analysisType == 'total'){
				var dates = 30;
				this.chart.tooltip.y.formatter = function (val) {
					return val ? val?.toLocaleString() + " 원" : val
				}
				for(var e of s){
					this.monthSeries.push(
						{
							name : e[0].keyword,
							data : e.map(f => f.monthSales).sort(function (a, b){return b - a})
						}
					)
					this.dailySeries.push(
						{
							name : e[0].keyword,
							data : e.map(f => parseInt(f.monthSales/dates)).sort(function (a, b){return b - a})
						}
					)								
				}
			} else {
				if(this.analysisType == 'keywordCVR'){
					this.chart.tooltip.y.formatter = function (val) {
						return val ? val?.toLocaleString() + " %" : val
					}
					for(e of s){
						if(e.map(f => f.impressions).length > 0){
							this.monthSeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => ((f.monthQty/30)/(f.impressions/28)) ? Math.round((f.monthQty/30)/(f.impressions/28)*10000)/100 : 0).sort(function (a, b){return b - a})
								}
							)
							this.dailySeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => ((f.monthQty/30)/(f.impressions/28)) ? Math.round((f.monthQty/30)/(f.impressions/28)*10000)/100 : 0).sort(function (a, b){return b - a})
								}
							)
						} else {
							this.monthSeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => f.monthSales).sort(function (a, b){return b - a})
								}
							)
							this.dailySeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => parseInt(f.monthSales/dates)).sort(function (a, b){return b - a})
								}
							)
						}								
					}
				} else {
					dates = 28;
					this.chart.tooltip.y.formatter = function (val) {
						return val ? val?.toLocaleString() + " 회" : val
					}
					for(e of s){
						if(e.map(f => f.impressions).length > 0){
							this.monthSeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => f.impressions).sort(function (a, b){return b - a})
								}
							)
							this.dailySeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => parseInt(f.impressions/dates)).sort(function (a, b){return b - a})
								}
							)
						} else {
							this.monthSeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => f.monthSales).sort(function (a, b){return b - a})
								}
							)
							this.dailySeries.push(
								{
									name : e[0].keyword,
									data : e.map(f => parseInt(f.monthSales/dates)).sort(function (a, b){return b - a})
								}
							)
						}								
					}
				}
			}

			var x = [];
			for (let i = 0; i < ylength; i++) {
				x.push(i+1);
			}
			this.chart.xaxis.categories = x;

		},
		async getauthNo(){
			axios.post('/api/getAuthNo')
			.then(res => {
				this.auth = res.data.auth;
			}).catch(console.log)
		},
		resetitems(){
			if(this.showspinner){
				alert('수집중인 상태에서는 삭제가 불가능합니다.')
				return
			}
			if(!confirm('전체 키워드 데이터를 삭제하시겠습니까?')){
				return
			}
			this.showspinner = false;
			this.showlist = false;
			this.showKeywordList = false;
			axios.post('/api/nomad/deleteKeywordAnalysisNomad',this.keywordList)
			.then(result => {
                if(result.data.res == 'OK'){
					this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:[]})
					this.keywordList = [];
					this.autoList = this.autoList.filter(e => !e.chartData);
                } else {
                    alert('삭제실패(600)')
                    return
                }
			})
		},
		resetAutoList(){
			if(this.showspinner){
				alert('수집중인 상태에서는 삭제가 불가능합니다.')
				return
			}
			if(!confirm('전체 키워드 데이터를 삭제하시겠습니까?')){
				return
			}
			this.showspinner = false;
			this.showlist = false;
			this.showKeywordList = false;
			axios.post('/api/nomad/deleteKeywordAnalysisNomad',this.autoList)
			.then(result => {
                if(result.data.res == 'OK'){
					this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:[]})
					this.keywordList = [];
					this.autoList = [];
                } else {
                    alert('삭제실패(600)')
                    return
                }
			})
		},
		searchenter(event){
			if(event.keyCode === 13){
				this.search();
			}
		},
		startfocus(){
			this.$refs.cursor.focus();
		},
		middlelevel(arr) {
			arr.sort((a,b) => a-b);
			var val = parseInt(arr.length/2);
			return arr[val];
		},
		async sleep(ms) {
			return new Promise((r) => setTimeout(r, ms));
		},
		async importresults(arr){
			console.log(arr)
			await this.sleep(1000);
			this.loadingtext = '키워드 별 쿠팡 판매량 분석 중';
			this.loadingrate = '';
			this.showspinner = true;
			// this.showlist = false;
			// this.showKeywordList = false;
			var ka = true;
			// if(this.keywordAnalysisEnable){
			// 	var ka = true;
			// } else {
			// 	ka = false;
			// }
			
			window.postMessage({greeting: "keywordanalysisNomad",data:arr,auth:this.auth,analysisType:this.analysisType,ka:ka,delayTime:this.delayTime},"*",)
		},
		resetitem(i){
			this.keywordList[i].targetKeyword = '';
			this.keywordList[i].productName = '';
			this.keywordList[i].itemId = '';
			this.keywordList[i].productId = '';
			this.keywordList[i].rank = [];
			this.keywordList[i].adqty = '';
			this.keywordList[i].adplace = '';
			this.keywordList[i].productqty = '';
			this.keywordList[i].rocketqty = '';
			this.keywordList[i].showspinner = false;
			this.keywordList[i].totalrank = [];
		},
		deleteitem(){
			if(this.showspinner){
				alert('수집중인 상태에서는 삭제가 불가능합니다.')
				return
			}
			if(confirm(this.tableSelectedItem.length + "개의 키워드를 삭제하시겠습니까?")){
				axios.post('/api/nomad/deleteKeywordAnalysisNomad',this.tableSelectedItem)
				.then(result => {
					if(result.data.res == 'OK'){
						this.autoList = this.autoList.filter(e => !this.tableSelectedItem.find(f => f._id == e._id));
						// this.autoList = this.autoList.slice(0,index).concat(this.autoList.slice(index+1));
						this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:this.autoList});
						this.keywordList = this.keywordList.filter(e => !this.tableSelectedItem.find(f => f._id == e._id));
						// this.keywordList = this.keywordList.slice(0,i).concat(this.keywordList.slice(i+1));
						if(this.keywordList.length > 0){
							this.showKeywordResult()
						} else {
							this.showspinner = false;
							this.showlist = false;
							this.showKeywordList = false;
						}
					} else {
						alert('삭제실패(600)')
						return
					}
				})
			}
		},
		deletelist(i){
			if(this.showspinner){
				alert('수집중인 상태에서는 삭제가 불가능합니다.')
				return
			}
			if(confirm("'" + this.autoList[i].targetKeyword + "' 키워드 데이터를 삭제하시겠습니까?")){
				axios.post('/api/nomad/deleteKeywordAnalysisNomad',[this.autoList[i]])
				.then(result => {
					if(result.data.res == 'OK'){
						this.autoList = this.autoList.slice(0,i).concat(this.autoList.slice(i+1));
						this.$store.commit('updateKeywordList',{keyword:this.activeKeyword,arr:this.autoList});
						this.showKeywordResult()
					} else {
						alert('삭제실패(600)')
						return
					}
				})
			}
		},	
		searchKeyword(list){
			this.targetKeyword = list.targetKeyword;
			this.$bvModal.hide('modal-4');
			this.search()
		},
		search(){
			if(!this.targetKeyword){
				alert('분석할 키워드를 입력해 주세요')
				return
			}
			if(this.showspinner){
				alert('수집중인 상태에서는 분석이 불가능합니다.')
				return
			}
			if(this.autoList.length == 0 || !this.autoList.find(e => e.targetKeyword == this.targetKeyword)){
				this.autoList.push({
					checked:true,
					targetKeyword:this.targetKeyword,
					companyid:this.$store.state.user.companyid
				})
			} else {
				if(this.autoList.find(e => e.targetKeyword == this.targetKeyword).chartData){
					if(!confirm('이미 수집된 키워드 입니다. 업데이트 하시겠습니까?')){
						return						
					}
				}
			}
			this.importresults([this.targetKeyword])
			return 
		},
		
		collectKeyword(){
			if(this.autoList.length == 0 || this.autoList.filter(e => !e.chartData).length == 0){
				alert('신규로 추가된 키워드가 없습니다. 수집할 키워드를 업로드해 주세요')
				return
			}
			var keywords = this.autoList.filter(e => !e.chartData).map(e => e.targetKeyword);
			this.importresults(keywords);
		},
		collectAll(){
			if(this.autoList.length == 0){
				alert('수집할 키워드가 없습니다. 수집할 키워드를 업로드해 주세요')
				return
			}
			var keywords = this.autoList.map(e => e.targetKeyword);
			this.importresults(keywords);
		},
		deepClone(obj) {
			if (obj === null || typeof obj !== "object") {
				return obj
			}

			const result = Array.isArray(obj) ? [] : {}

			for (let key of Object.keys(obj)) {
				result[key] = this.deepClone(obj[key])
			}

			return result
		}, 		
		todate(date){
			var today = new Date(date); 
			today.setHours(today.getHours() + 9); 
			return today.toISOString().replace('T', ' ').substring(0, 19);
		},
        timestamp(date){
            //inputdate = "yyyy-mm-dd"
            return new Date(date).getTime()
        },		
		getTodaywith(){
			var date = new Date();
			var year = date.getFullYear();
			var month = ("0" + (1 + date.getMonth())).slice(-2);
			var day = ("0" + date.getDate()).slice(-2);

			return year + "-" + month + "-" + day;
		},   
		download() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			var arr = [];
			this.keywordList.forEach(e => {
				arr.push({
					키워드 : e.targetKeyword,

					카테고리코드1 : e.categoryCode1,
					카테고리코드2 : e.categoryCode2,
					카테고리코드3 : e.categoryCode3,

					카테고리코드1적합도 : e.categorySuitability1,
					카테고리코드2적합도 : e.categorySuitability2,
					카테고리코드3적합도 : e.categorySuitability3,

					키워드최대매출 : e.maxSales,
					키워드중간매출 : e.middleSales,
					키워드평균매출 : e.sales,
					키워드평균조회수 : e.impressions,
					키워드결제전환율 : e.cvr,


					쿠팡1p윙비율:e.wingrate,
					쿠팡1p윙제품수 : e.wing1p,
					쿠팡1p로켓제품수 : e.rocket1p,
					쿠팡1p그로스제품수 : e.growth1p,
					// 쿠팡광고비율 : e.adrate,
					// 쿠팡광고상품수 : e.adqty,
					// 쿠팡광고구좌수: e.adplace,
					// 쿠팡광고CPC단가_추천: e.cpc,
					// 쿠팡광고CPC단가_최대: e.cpchigh,
					// 쿠팡광고CPC단가_최저: e.cpclow,
					쿠팡로켓비율: e.rocketrate,
					쿠팡로켓상품수: e.rocketqty,
					쿠팡전체상품수: e.totalqty,

					쿠팡1p리뷰수_평균: e.review1p,
					쿠팡1p리뷰수_최대: e.review1phigh,
					쿠팡1p리뷰수_최저: e.review1plow,
					쿠팡1p리뷰수_중간: e.review1pmiddle,

					쿠팡최적판매가 : parseInt(e.maxSalesPrice) + "~" + (parseInt(e.maxSalesPrice)+1000),
					쿠팡1p판매가_평균: e.price1p,
					쿠팡1p판매가_최대: e.price1phigh,
					쿠팡1p판매가_최저: e.price1plow,
					쿠팡1p판매가_중간: e.price1pmiddle,

					네이버1p판매가_평균: e.pricen1p,
					네이버1p판매가_최대: e.pricen1phigh,
					네이버1p판매가_최저: e.pricen1plow,
					네이버1p판매가_중간: e.pricen1pmiddle,

					네이버1p월판매수량_평균: e.n1psalesqty,
					네이버1p월판매수량_최대: e.n1psalesqtyhigh,
					네이버1p월판매수량_최저: e.n1psalesqtylow,
					네이버1p월판매수량_중간: e.n1psalesqtymiddle,
					네이버1p월매출_평균: e.n1psales,
					네이버1p월매출_최대: e.n1psaleshigh,
					네이버1p월매출_최저: e.n1psaleslow,
					네이버1p월매출_중간: e.n1psalesmiddle,					
				})
			})
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(arr)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '키워드분석')
			XLSX.writeFile(workBook, '키워드분석' + '_' + getToday() + '.xlsx')
		},
		downloadMarketAbility() {
			function getToday(){
				var date = new Date();
				var year = date.getFullYear();
				var month = ("0" + (1 + date.getMonth())).slice(-2);
				var day = ("0" + date.getDate()).slice(-2);

				return year + month + day;
			}
			const workBook = XLSX.utils.book_new()
			const workSheet1 = XLSX.utils.json_to_sheet(this.productList)
			XLSX.utils.book_append_sheet(workBook, workSheet1, '상품성분석')
			XLSX.writeFile(workBook, '상품성분석' + '_' + getToday() + '.xlsx')
		},
		settinglist(){
			
		}
	}

}
</script>

<style>
.my-class .dropdown-menu {

max-height: 300px;
width:800px;
overflow-y: auto;
}
</style>